.filled {
  background-color: var(--color50);
  box-shadow: var(--shadow8dp);
  color: var(--color0);
}

.smooth {
  background-color: var(--color20);
  box-shadow: var(--shadow8dp);
  color: var(--color90);
}

.outlined {
  background-color: var(--color0);
  box-shadow: var(--shadow8dp);
  color: var(--color70);
  border: 1px solid var(--color40);
}
