.chevron {
  cursor: pointer;
  transform: scale(-1, 1);
  color: var(--grey30);
  transition: color 0.2s, transform 0.2s ease-in-out;

  &:hover {
    color: var(--grey70);
  }

  &_reversed {
    transform: scale(1, -1);
  }
}
