.files {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.file {
  position: relative;
  border-radius: 4px;
  height: 38px;
  width: 38px;
  cursor: auto;
}
.badge {
  z-index: 999;
  top: 2px;
}
