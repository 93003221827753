.operation-cell-renderer {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &__count {
    margin-left: 4px;
    color: var(--grey40);
  }
}
