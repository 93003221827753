/* BUTTONS TYPES*/
/*--DEFAULT--*/
.default {
  height: 2.5rem;
  align-items: center;
}
.dense {
  height: 1.75rem;
  align-items: center;
}
.default[class*="button-group"] > button[class*="button"] {
  min-width: 3.25rem;
}
.dense[class*="button-group"] > button[class*="button"] {
  min-width: 2.5rem;
}
.right {
  float: right;
}
